import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ViaCepService {
    viaCep = 'https://viacep.com.br/ws';

    constructor(
        private http: HttpClient
    ) { }

    searchAddress(zipcode, returnType = 'json'): Observable<any> {
        return this.http.get(`https://viacep.com.br/ws/${zipcode}/json/`);

    }
    
    getAddress(cep: any) {
        return this.http.get(`https://viacep.com.br/ws/${cep}/json`);
    }

}