import { LoginGuard } from './guards/login.guard';
import { AdminGuard } from './guards/admin.guard';
import { AuthInterceptor } from './services/auth.interceptor';
import { StorageService } from './services/localsorage.service';
import { LoginService } from './services/login.service';
import { SharedModule } from './../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { PoliticasDePrivacidadeComponent } from './politicas-de-privacidade/politicas-de-privacidade.component';
import { TermosDeUsoComponent } from './termos-de-uso/termos-de-uso.component';
import { EsqueceuSenhaComponent } from './esqueceu-senha/esqueceu-senha.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import localePt from "@angular/common/locales/pt";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BemVindoComponent } from './bem-vindo/bem-vindo.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ViaCepService } from './services/via-cep.service';

registerLocaleData(localePt);


@NgModule({
  declarations: [
    LoginComponent,
    PoliticasDePrivacidadeComponent,
    TermosDeUsoComponent,
    EsqueceuSenhaComponent, BemVindoComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-left'
    }),
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        LoginService,
        StorageService,
        AdminGuard,
        LoginGuard,
        ViaCepService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID,useValue: "pt-BR"},
      ],
    };
  }
}
