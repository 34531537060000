import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {

  abstract urlService();
  constructor(protected http: HttpClient) {}

  public get(page?: number, limite?:number): Observable<any>{
    let filter
    if(!limite) limite = 10
    if(page>=1){ filter = new HttpParams().set('limit', `${limite}`).append('page', `${page}`)}
    return this.http.get(`${API}/${this.urlService()}/`, {params: filter})
  }

  public getId(id,page?: any, limite?:number): Observable<any>{
    let filter
    if(!limite) limite = 10
    if(page>=1){ filter = new HttpParams().set('limit', `${limite}`).append('page', `${page}`)}

    return this.http.get(`${API}/${this.urlService()}/${id}`, {params: filter})
  }

  public post(data): Observable<any>{
    return this.http.post(`${API}/${this.urlService()}/`, data)
  }

  public put(id: number, data): Observable<any>{
    return this.http.put(`${API}/${this.urlService()}/${id}`, data)
  }

  public delete(id): Observable<any>{
    return this.http.delete(`${API}/${this.urlService()}/${id}`)
  }

  public search(url:string, filter?: any): Observable<any> {
    return this.http.get(`${API}/${url}`, { params: filter });
  }

  public getByName(name: string, query:string): Observable<any> {
    let filter = new HttpParams().set(`${query}`, name)
    return this.search(`${this.urlService()}`, filter)
  }

  public getByDate(name: string): Observable<any> {
    let filter = new HttpParams().set('date', name)
    return this.search(`${this.urlService()}`, filter)
  }

}
