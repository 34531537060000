import { SearchFieldComponent } from './components/search-field/search-field.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {}
import { NgxCurrencyModule, CurrencyMaskInputMode } from "ngx-currency";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { NgxPaginationModule } from 'ngx-pagination';
import { ErroMessageComponent } from './components/erro-message/erro-message.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);


export const CustomCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
}

@NgModule({
  declarations: [
    SidebarComponent,
    PaginationComponent,
    SearchFieldComponent,
    ErroMessageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxMaskModule.forRoot(options),
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
    FullCalendarModule,
    NgxPaginationModule
  ],
  exports: [
    SidebarComponent,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    FullCalendarModule,
    PaginationComponent,
    SearchFieldComponent,
    ErroMessageComponent
  ]
})
export class SharedModule { }
