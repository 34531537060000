import { LoginService } from './../services/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { patterns } from 'src/app/shared/helper/patterns.helper';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.scss']
})
export class EsqueceuSenhaComponent implements OnInit {

  loginForm: FormGroup
  loginTokenForm: FormGroup
  forgotToken: boolean = false
  qttForRequisition:number =1

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private loginService: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(patterns.email)]]
    });
    this.loginTokenForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(patterns.email)]],
      token: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  submit(){
    if(this.loginForm.valid){
      this.spinner.show()
      this.loginService.forgotPassword(this.loginForm.value).subscribe(response =>{
        this.spinner.hide()
        this.forgotToken = true
        this.toastr.success('Alterado', 'Sucesso!')
      }, error=>{
        this.spinner.hide()
        this.toastr.error('Usuário não encontrado', 'Error!')
        this.loginForm.reset()
      })
    }else{
      this.toastr.error('Preencha os campos inválidos')
    }
  }

  checkInvalidField(name) {
    return this.loginTokenForm.get(name).invalid && this.loginTokenForm.get(name).touched;
  }

  submitToken(){
    if(this.loginTokenForm.valid && this.equalsForm){
      this.spinner.show()
      this.loginService.forgotPasswordToken(this.loginTokenForm.value).subscribe(response =>{
        this.spinner.hide()
        this.toastr.success('Alterado', 'Sucesso!')
        this.router.navigate(['/'])
      }, error=>{
        if(this.qttForRequisition == 4){
          this.router.navigate(['/'])
          this.toastr.error('Quantidade de tentativas excedida!', 'Tente novamente !')
        }
        this.qttForRequisition++
        this.spinner.hide()
        this.toastr.error('Verifique se o Token ou se as senhas estão corretas', 'Error!')
        this.loginTokenForm.reset()
      })
    }else{
      this.toastr.error('Preencha os campos inválidos')
    }
  }

  get email(){
    return this.loginForm.get('email').value
  }

  get equalsForm(){
    return this.loginTokenForm.get('password').value == this.loginTokenForm.get('confirmPassword').value
  }
}
