import { TermsOfUseService } from './../services/terms-of-use.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-termos-de-uso',
  templateUrl: './termos-de-uso.component.html',
  styleUrls: ['./termos-de-uso.component.scss'],
  animations: [
    trigger('ItemAppeared', [
      state('ready', style({ opacity: 1 })),
      transition('void => ready', [
        style({ opacity: 0, transform: 'translateY(-50px)' }),
        animate('800ms 0s ease-in')
      ])
    ])
  ]
})
export class TermosDeUsoComponent implements OnInit {

  ItemState = 'ready'
  terms

  constructor(
    private toastr: ToastrService,
    private termsService: TermsOfUseService
  ) { }

  ngOnInit(): void {
    this.startItens()
  }

  async startItens(){
    try{
      await this.getTerms()
    }catch{
      this.toastr.error('Não foi possível carregar os termos de uso')
    }
  }

  getTerms(): Promise<any>{
    return new Promise((resolve, reject)=>{
      this.termsService.get().subscribe(response =>{
        this.terms = response.content
        resolve(response)
      }, error=>{ error(reject) })
    })
  }

}
