<div class="background">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <img class="mt-5" src="assets/img/medplantoes.svg">
                </div>
                <div class="card p-lg-5 p-3 mb-5 mt-5" [@ItemAppeared]="ItemState">
                    <p [innerHTML]="terms"></p>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="button1 bton mb-3" routerLink="/">home</button>
                </div>
            </div>
        </div>
    </div>
</div>
