import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseService extends BaseService {

  urlService(){
    return 'term-uses'
  }
}
