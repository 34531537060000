import { LoginService } from './../../../core/services/login.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  controllerSideBar
  @Input() itens

  constructor(
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
  }

  logout(){
    this.loginService.logout()
  }

}
