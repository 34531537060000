<div class="background">
  <div class="container">
      <div class="row contain align-items-center justify-content-center" >
          <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-center">
                  <div class="col-md-6 col-12">
                      <div class="card" [@ItemAppeared]="ItemState">
                          <div class="text-center">
                            <img class="w-50" id="img" src="assets/img/logo.svg">
                          </div>
                          <div class="row">
                              <div class="col-md-12 col-12 text-center pl-5 pr-5 mt-2">
                                  <router-outlet></router-outlet>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
