import { LoginGuard } from './core/guards/login.guard';
import { BemVindoComponent } from './core/bem-vindo/bem-vindo.component';
import { TermosDeUsoComponent } from './core/termos-de-uso/termos-de-uso.component';
import { PoliticasDePrivacidadeComponent } from './core/politicas-de-privacidade/politicas-de-privacidade.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { EsqueceuSenhaComponent } from './core/esqueceu-senha/esqueceu-senha.component';
import { AdminGuard } from './core/guards/admin.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard], children: [
    { path: '', component: BemVindoComponent },
    { path: 'esqueceu-senha', component: EsqueceuSenhaComponent },
  ] },
  { path: 'politicas-de-privacidade', component: PoliticasDePrivacidadeComponent },
  { path: 'termos-de-uso', component: TermosDeUsoComponent },
  { path: 'admin',  loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule), canLoad:[ AdminGuard], canActivate: [ AdminGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
