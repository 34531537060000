<ng-container *ngIf="!forgotToken">
  <form [formGroup]="loginForm" (ngSubmit)="submit()">
      <div class="row">
          <div class="col-md-12">
              <h2 class="mb-3">Esqueceu sua senha?</h2>
              <p>Enviaremos um código de recuperação para o seu email ex***@mail.com</p>
              <input formControlName="email" type="email" class="form-group mt-3" placeholder="E-mail">
              <button type="submit" class="bton button1 mt-2">enviar</button>
              <div class="mb-5 mt-2">
                  <a routerLink="/" class="link2">Voltar</a>
              </div>
          </div>
      </div>
  </form>
</ng-container>
<ng-container *ngIf="forgotToken">
  <form [formGroup]="loginTokenForm" (ngSubmit)="submitToken()">
    <p>Enviamos um código de recuperação para o seu email:<br> <b>{{ email }}</b></p>
    <input formControlName="email" type="email" class="form-group mt-3" placeholder="E-mail"
    [ngClass]="{'is-invalid': checkInvalidField('email')}">
    <input formControlName="token" type="text" class="form-group mt-3" placeholder="Token"
    [ngClass]="{'is-invalid': checkInvalidField('email')}">
    <input formControlName="password" type="password" class="form-group mt-3" placeholder="Nova Senha"
    [ngClass]="{'is-invalid': checkInvalidField('confirmPassword') || !equalsForm}">
    <input formControlName="confirmPassword" type="password" class="form-group mt-3" placeholder="Confirmação da Nova Senha"
    [ngClass]="{'is-invalid': checkInvalidField('confirmPassword') || !equalsForm}">
    <button type="submit" class="bton button1 mt-2">enviar</button>
    <div class="mb-5 mt-2">
        <a routerLink="/" class="link2">Voltar</a>
    </div>
  </form>
</ng-container>
