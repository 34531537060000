import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, Injector, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { PaginationInstance } from 'ngx-pagination';
import { BaseService } from 'src/app/core/services/base-service.service';

@Component({
  selector: "app-search-field",
  templateUrl: "./search-field.component.html",
  styleUrls: ["./search-field.component.scss"]
})
export class SearchFieldComponent implements OnInit {

  @Input() placeholder = "";
  @Input() service: BaseService;
  @Output() searchResult: EventEmitter<Object> =  new EventEmitter<Object>();
  @Output() searchResultItem: EventEmitter<boolean> =  new EventEmitter<boolean>();
  @Input() url: string;
  @Input() query: string;

  public searchForm: FormGroup

  constructor(
    private fb: FormBuilder,
    private baseService: BaseService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
    ) {
    this.searchForm = this.fb.group({
      search: ''
    })
   }

  ngOnInit() {}

  search(page?:number){
    if(!page) page=1
    this.baseService.search(this.url, this.setFilter( page,this.searchName)).subscribe(data=>{
      this.router.navigate([], {
        queryParams: {search: `${this.searchName}`, page: `${data.page}`, url: `${this.url}` ,query: `${this.query}`},
        relativeTo: this.route,
        replaceUrl: true,
      });
      this.searchResult.emit(data)
    }, error=>{
      this.toastr.error('Não foi possível buscar os itens')
    })
  }

  setFilter(page?: number, filter?:any){
    let filtro
    if(!page) page = 1
    if(page>=1){
      filtro = new HttpParams().set('limit', '10').set(`${this.query}`, `${filter}`).set('page', `${page}`)
      return filtro
    }
  }

  get searchName(){
    return this.searchForm.get('search').value
  }

}
