
<form [formGroup]="loginForm" (ngSubmit)="submit()">
    <div class="row">
        <div class="col-md-12">
            <h2>Login gerencial</h2>
            <input type="email" formControlName="email" class="form-group mt-5" placeholder="E-mail">
            <input type="password" formControlName="password" class="form-group mt-2" placeholder="*****">
            <div class="text-left">
                <a routerLink="esqueceu-senha" class="link1 w-100">Esqueci minha senha</a>
            </div>
            <button type="submit" class="bton button1 mt-2 mb-5">entrar</button>
            <div class="d-flex justify-content-between mb-4">
                <a routerLink="/termos-de-uso" class="link2">TERMOS DE USO</a>
                <a routerLink="/politicas-de-privacidade" class="link2">POLÍTICAS DE PRIVACIDADE</a>
            </div>
        </div>
    </div>
</form>