<div class="wrapper" id="a">
    <nav id="sidebar" [ngClass]="{ active: controllerSideBar }">
      <div class="nav-item text-left nav-top">
        <div class="px-2 d-flex align-items-center justify-content-between item" >
          <img src="assets/img/logo_sidebar.svg">
        </div>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item" *ngFor="let item of itens">
          <a *ngIf="!item.subitem" class="nav-link f-1 py-3 w-100" [routerLink]="item.route" routerLinkActive="activeRoute">
            <div class="d-flex align-items-center h-100">
              <div class="col-1 text-center px-0">
                <img class="mr-2 w-100" [src]="item.img">
              </div>
              <div class="col-11">
                  <div class="d-flex justify-content-start">
                    <span class="f-14">{{ item.name }}</span>
                  </div>
              </div>
            </div>
          </a>
          <a *ngIf="item.subitem" class="nav-link f-1 py-3 w-100" [routerLink]="item.route" routerLinkActive="activeRoute"
              data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            <div class="d-flex align-items-center h-100">
              <div class="col-1 text-center px-0">
                <img class="mr-2 w-100" [src]="item.img">
              </div>
              <div class="col-11">
                  <div class="d-flex justify-content-start">
                    <span class="f-14">{{ item.name }}</span>
                  </div>
              </div>
            </div>
          </a>
          <ul *ngIf="item.subitem" id="collapseOne" class="list-unstyled collapse" >
            <li class="nav-item">
              <a class="nav-link py-3 nav-link2" routerLink="configuracoes/textos" routerLinkActive="activeSubRoute" >
                <span class="f-075">TEXTOS</span>
              </a>
            </li>
            <li class="nav-item w-100">
              <a  class="nav-link py-3 nav-link2"  routerLink="configuracoes/faq" routerLinkActive="activeSubRoute" >
                <span class="f-075">FAQ</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link f-1 py-3 w-100" (click)="logout()">
            <div class="d-flex align-items-center h-100">
              <div class="col-1 text-center px-0">
                <img class="mr-2 w-100" src="assets/icons/sidebar_exit.svg">
              </div>
              <div class="col-11">
                  <div class="d-flex justify-content-start">
                    <span class="f-14">Sair</span>
                  </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </nav>
    <div id="content" style="width: 100%; height: 100%;">
      <nav class="navbar-light">
        <div class="container-fluid" style="padding-left: 0; padding-right: 0;">
          <button id="sidebarCollapse" (click)="controllerSideBar = !controllerSideBar" class="navbar-toggler custom-toggler p-2">
            <span class="navbar-toggler-icon"></span>
          </button>
          <ng-content></ng-content>
        </div>
      </nav>
    </div>
  </div>
