import { PrivacyPoliciesService } from './../services/privacy-policies.service';
import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-politicas-de-privacidade',
  templateUrl: './politicas-de-privacidade.component.html',
  styleUrls: ['./politicas-de-privacidade.component.scss'],
  animations: [
    trigger('ItemAppeared', [
      state('ready', style({ opacity: 1 })),
      transition('void => ready', [
        style({ opacity: 0, transform: 'translateY(-50px)' }),
        animate('800ms 0s ease-in')
      ])
    ])
  ]
})
export class PoliticasDePrivacidadeComponent implements OnInit {

  ItemState = 'ready'
  privacy

  constructor(
    private toastr: ToastrService,
    private privacyService: PrivacyPoliciesService
  ) { }

  ngOnInit(): void {
    this.startItens()
  }

  async startItens(){
    try{
      await this.getPrivacy()
    }catch{
      this.toastr.error('Não foi possível carregar as políticas de privacidade')
    }
  }

  getPrivacy(): Promise<any>{
    return new Promise((resolve, reject)=>{
      this.privacyService.get().subscribe(response =>{
        this.privacy = response.content
        resolve(response)
      }, error=>{ error(reject) })
    })
  }

}
