import { LoginService } from './../services/login.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { patterns } from 'src/app/shared/helper/patterns.helper';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-bem-vindo',
  templateUrl: './bem-vindo.component.html',
  styleUrls: ['./bem-vindo.component.scss']
})
export class BemVindoComponent implements OnInit {

  loginForm: FormGroup;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private loginService: LoginService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(patterns.email)]],
      password: ["", [Validators.required]]
    });
  }

  submit(){
    if(this.loginForm.valid){
      this.spinner.show()
      this.loginService.login(this.email.value, this.password.value).subscribe(response =>{
        this.spinner.hide()
        this.toastr.success('Entrando')
        this.router.navigate(['/admin'])
      }, error=>{
        this.spinner.hide()
        this.toastr.error('E-mail ou Senha Inválido')
        this.loginForm.reset()
      })
    }else{
      this.toastr.error('Preencha os campos inválidos')
    }
  }

  checkInvalidField(name) {
    return this.loginForm.get(name).invalid && this.loginForm.get(name).touched;
  }

  get email(){
    return this.loginForm.get('email')
  }

  get password(){
    return this.loginForm.get('password')
  }
}
